<template>
    <LiefengContent :isBack="$route.query.isLineHome ? true : false" @backClick="backClick">
        <template #title>
            居民邮箱管理
            <!-- <Button type="error" v-if="$route.query.isLineHome" @click="">返回</Button> -->
            <span class="eye" @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
        </template>
        <template #toolsbarLeft>
            <LiefengTree ref="mychild" :fixedmenu="true" @handleData="getList" style="width: 200px" treeID="mychild" fixedContentID="contentarea"></LiefengTree>
        </template>
        <template #toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Input v-model.trim="selectObj.nameOrMobile" @on-search="search" search style="width: 260px" enter-button="搜索" placeholder="按姓名、手机号搜索" />
                </FormItem>
                <Button type="primary" icon="ios-add" @click="addGroupSend">新增群发消息</Button>
            </Form>
        </template>
        <template #contentArea>
            <div class="container">
                <Tabs
                    v-model="selectObj.status"
                    @on-click="
                        total = 0
                        search()
                    "
                    :animated="false"
                    type="card"
                >
                    <TabPane :label="label" name="1"></TabPane>
                    <TabPane label="全部" name="all"></TabPane>
                </Tabs>
                <LiefengTable
                    :tableData="tableData"
                    :talbeColumns="talbeColumns"
                    :loading="loading"
                    :pageSize="pageSize"
                    :pagesizeOpts="[20, 30, 50, 100, 200]"
                    :total="total"
                    :height="tableHeight"
                    :curPage="page"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </div>
            <!-- 窗口 -->
            <LiefengModal :fullscreen="true" :title="windowObj.userName + ' (共 ' + windowObj.total + ' 条记录)'" :value="enterWindowStatus" @input="enterWindowStatusFn">
                <template #contentarea>
                    <div v-if="enterWindowStatus">
                        <div style="line-height: 40px">
                            <Button @click="exportExcel" style="margin-left: 4px" type="success" icon="ios-download-outline">导出聊天内容</Button>
                            <Anchor
                                v-show="windowObj.unreadCount > 0"
                                ref="anchor"
                                :affix="false"
                                container="#container"
                                style="position: absolute; display: inline-block"
                                @on-select="gotoUnread"
                                @on-change="changeAnchor"
                            >
                                <AnchorLink :href="'#' + windowObj.firstReplyId" :title="windowObj.unreadCount + '条消息未读'"></AnchorLink>
                                <Icon type="ios-arrow-up" />
                            </Anchor>
                        </div>
                        <div id="container">
                            <!-- <div
                v-if="enterWindowData.length == windowObj.total && windowObj.total > 0"
                style="
                  border: 1px solid #efefef;
                  border-radius: 4px;
                  line-height: 50px;
                  background-color: rgb(248, 248, 249);
                  text-align: center;
                  color: #aaa;
                "
              >
                <i style="text-align: center; color: #aaa; font-size: 18px"
                  >我已经到头啦 </i
                >
                <Icon type="md-happy" style="font-size: 18px" />
              </div> -->
                            <div
                                v-if="windowObj.total == 0"
                                style="height: 100%;font-size: 20px;color: #fff;background: #ccc;border: 1px solid #ccc;border-radius: 4px;display: flex;justify-content: center;align-items: center"
                            >
                                暂无聊天记录
                            </div>
                            <Button
                                v-if="enterWindowData.length < windowObj.total"
                                @click="loadingMoreFn"
                                :loading="btnLoadingMore"
                                style="
                  width: 100%;
                  height: 50px;
                  border: 1px solid #efefef;
                  border-radius: 4px;
                  line-height: 50px;
                  background-color: rgb(248, 248, 249);
                  text-align: center;
                  color: #aaa;
                "
                            >
                                <span v-if="!btnLoadingMore">点击加载更多</span>
                                <span v-else>正在加载数据，请稍等...</span>
                            </Button>

                            <div v-for="(item, index) in enterWindowData" :key="index" :id="item.replyId">
                                <List :class="item['replyType'] == '1' ? 'flexStart' : 'flexEnd'">
                                    <div
                                        slot="header"
                                        :style="{
                                            textAlign: item['replyType'] == '1' ? 'left' : 'right',
                                        }"
                                    >
                                        {{ item.replyType == "1" ? item.replier : "居委回复" }}
                                        {{ item.gmtCreate | filterTime(that) }}
                                        <span
                                            :style="{
                                                color: item.status == '1' ? '#ed4014' : '#c3cbd6',
                                            }"
                                            v-if="item.replyType == '1'"
                                        >
                                            {{ item.status == "1" ? "未读" : "已读" }}
                                        </span>
                                    </div>
                                    <ListItem
                                        :style="{
                                            background: item['replyType'] == '1' ? '#f8f8f9' : 'rgb(149,235,108)',
                                        }"
                                    >
                                        <div style="word-break:break-all;">{{ item.content }}</div>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                        <div style="display: flex; align-items: flex-end; margin-top: 10px">
                            <Input show-word-limit :maxlength="800" v-model.trim="windowObj.content" type="textarea" :rows="4" placeholder="请输入回复内容，限800字" style="flex: 1" />
                            <Button style="margin-left: 10px" type="primary" @click="replySend" :loading="btnReplyLoading">
                                <span v-if="!btnReplyLoading">确定回复</span>
                                <span v-else>正在发送</span>
                            </Button>
                        </div>
                    </div>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/emailindex');
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
import LiefengTree from "@/views/linehomemanage/childrens/LiefengTree"
export default {
    data() {
        return {
            that: this,
            label: h => {
                return h("div", [
                    h(
                        "span",
                        {
                            style: {
                                marginRight: "4px",
                            },
                        },
                        "待回复居民"
                    ),
                    h("Badge", {
                        props: {
                            count: this.total,
                        },
                        style: {
                            display: this.total > 0 && this.selectObj.status == "1" ? "inline-block" : "none",
                        },
                    }),
                ])
            },
            selectObj: {
                status: "1",
                nameOrMobile: "",
            },

            tableData: [],
            talbeColumns: [
                {
                    title: "姓名",
                    width: 200,
                    align: "center",
                    key: "userName",
                },
                {
                    title: "手机号码",
                    width: 140,
                    align: "center",
                    key: "mobile",
                },
                {
                    title: "内容",
                    minWidth: 200,
                    align: "center",
                    key: "content",
                },
                {
                    title: "最新时间",
                    width: 200,
                    align: "center",
                    key: "gmtModified",
                },
                {
                    title: "时间间隔",
                    width: 120,
                    align: "center",
                    key: "interval",
                },
                {
                    title: "操作",
                    width: 150,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "Button",
                            {
                                props: {
                                    size: "small",
                                    type: "primary",
                                },
                                on: {
                                    click: () => {
                                        this.windowObj.dataScopeId = params.row.dataScopeId
                                        this.windowObj.houseCode = params.row.houseCode
                                        this.windowObj.page = 1
                                        this.windowObj.pageSize = 20
                                        this.windowObj.replierId = params.row.replierId
                                        this.windowObj.userName = params.row.userName
                                        this.windowObj.index = 1 //标识第一次进入窗口
                                        this.$Message.loading({
                                            background: true,
                                            content: "正在加载数据，请稍等...",
                                        })
                                        this.getEnterWindowData()
                                    },
                                },
                            },
                            "进入他的窗口"
                        )
                    },
                },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            tableHeight: "",
            page: 1,
            // 进入聊天窗口
            enterWindowStatus: false,
            windowObj: {
                pageSize: 20,
                page: 1,
                replierId: "",
                total: 0,
                firstReplyId: "",
                unreadCount: 0,
                showAnchor: false,
                dataScopeId: "",
                houseCode: "",
                userName: "",
                firstMessagePage: 1,
                newFirstReplyId: "",
            },
            enterWindowData: [],
            btnLoadingMore: false,
            btnReplyLoading: false,

            //脱敏
            hasSensitive: true,
        }
    },
    methods: {
        // 导出聊天记录
        exportExcel() {
            this.$Message.destroy()
            this.$Message.loading({
                duration: 0,
                content: "正在导出，请稍等...",
            })

            this.$.ajax({
                url: window.vue.getProxy()["/sendmsg"].target + "/upload/messageReply/export",
                type: "POST",
                xhrFields: { responseType: "blob" },
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    openId: window.sessionStorage.getItem("openId"),
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    dataScopeId: this.windowObj.dataScopeId,
                    replierId: this.windowObj.replierId,
                },
                success: res => {
                    if (res.code != 500) {
                        const blob = res
                        const downloadElement = document.createElement("a")
                        const href = window.URL.createObjectURL(blob)
                        downloadElement.href = href
                        downloadElement.download = this.windowObj.userName + "聊天记录.xlsx"
                        document.body.appendChild(downloadElement)
                        downloadElement.click()
                        document.body.removeChild(downloadElement)
                        window.URL.revokeObjectURL(href)
                        this.$Message.destroy()
                    } else {
                        this.$Message.destroy()
                        this.$Message.error({
                            background: true,
                            content: "导出失败，请联系管理员处理",
                        })
                    }
                },
            })
        },
        // 群发消息
        addGroupSend() {
            if (this.$route.query.isLineHome) {
                this.$router.push({
                    path: "/emailgroup",
                    query: {
                        menuId: this.$route.query.menuId,
                        isLineHome: this.$route.query.isLineHome,
                    },
                })
                return
            }
            this.$router.push({
                path: "/emailgroup",
                query: {
                    menuId: this.$route.query.menuId,
                },
            })
        },

        // 窗口
        // 加载更多
        loadingMoreFn() {
            this.windowObj.pageSize += 20
            this.btnLoadingMore = true
            this.getEnterWindowData(true)
        },
        // 确定回复
        replySend() {
            if (!this.windowObj.content) {
                this.$Message.error({
                    background: true,
                    content: "请输入回复内容",
                })
                return
            }
            this.btnReplyLoading = true
            this.$post("/sendmsg/pc/chat/communityMessage/addReply", {
                dataScopeId: this.windowObj.dataScopeId,
                replierId: this.windowObj.replierId,
                content: this.windowObj.content,
                staffName: parent.vue.loginInfo.userinfo.realName,
                staffId: parent.vue.loginInfo.userinfo.custGlobalId,
                replier: this.windowObj.userName.split("-")[this.windowObj.userName.split("-").length - 1],
            })
                .then(res => {
                    if (res.code == 200) {
                        this.btnReplyLoading = false
                        this.windowObj.content = ""
                        this.windowObj.index = 1
                        this.getEnterWindowData()
                        this.hadlePageSize({
                            page: this.page,
                            pageSize: this.pageSize,
                        })
                    } else {
                        this.btnReplyLoading = false
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.btnReplyLoading = false
                    this.$Message.error({
                        background: true,
                        content: "发送失败，请联系管理员处理",
                    })
                })
        },

        //   锚点跳转
        gotoUnread(url) {
            if (!this.windowObj.showAnchor) {
                this.windowObj.pageSize = this.windowObj.pageSize * this.windowObj.firstMessagePage
            }
            this.getEnterWindowData()
        },
        changeAnchor(newV, oldV) {},
        getEnterWindowData(nowPosition) {
            let promise1 = this.$get("/sendmsg/pc/chat/communityMessage/queryReplyUnreadInfo", {
                dataScopeId: this.windowObj.dataScopeId,
                replierId: this.windowObj.replierId,
                pageSize: this.windowObj.pageSize,
                replyType: "1",
            })
            let promise2 = this.$get("/sendmsg/pc/chat/communityMessage/queryReplyPage", {
                dataScopeId: this.windowObj.dataScopeId,
                page: this.windowObj.page,
                pageSize: this.windowObj.pageSize,
                replierId: this.windowObj.replierId,
            })
            Promise.all([promise1, promise2])
                .then(res => {
                    if (res[0].code == 200 && res[1].code == 200 && res[0].data && res[1].dataList) {
                        this.enterWindowStatus = true
                        if (res[0].data.firstMessagePage > 1) {
                            this.windowObj.showAnchor = false
                        } else {
                            this.windowObj.showAnchor = true
                        }
                        if (res[1].dataList.length > 0) {
                            if (this.windowObj.index == 1) {
                                //代表是第一次进来。滚动到最底部
                                this.windowObj.firstReplyId = res[1].dataList[res[1].dataList.length - 1].replyId
                                this.windowObj.newFirstReplyId = res[0].data.firstReplyId
                            } else if (nowPosition) {
                                //点击加载更多，阻止滚动
                                this.windowObj.firstReplyId = this.enterWindowData[0].replyId
                            } else if (this.windowObj.showAnchor) {
                                this.windowObj.firstReplyId = res[0].data.firstReplyId
                            } else {
                                this.windowObj.firstReplyId = res[1].dataList[0].replyId
                                this.windowObj.newFirstReplyId = res[0].data.firstReplyId
                            }
                            location.replace(
                                location.hash.substring(0, location.hash.lastIndexOf("#") == 0 ? location.hash.length : location.hash.lastIndexOf("#")) + "#" + this.windowObj.firstReplyId
                            )
                        }

                        this.windowObj.index = 2
                        this.enterWindowData = res[1].dataList
                        this.windowObj.total = res[1].maxCount
                        this.windowObj.firstMessagePage = res[0].data.firstMessagePage
                        this.windowObj.unreadCount = res[0].data.unreadCount
                        this.btnLoadingMore = false
                        this.$Message.destroy()

                        // 改变信息状态
                        let arr = []
                        res[1].dataList.map(item => {
                            if (item.replyType == "1" && item.status == "1") {
                                arr.push({
                                    messageId: item.messageId,
                                    replyId: item.replyId,
                                })
                            }
                        })
                        if (arr.length > 0) {
                            this.$post(
                                "/sendmsg/pc/chat/communityMessage/updateStatusRead",
                                {
                                    ros: arr,
                                    userId: parent.vue.loginInfo.userinfo.id,
                                    userName: parent.vue.loginInfo.userinfo.realName,
                                },
                                { "Context-Type": "application/json" }
                            ).then(res => {
                                if (res.code != 200) {
                                    this.$Message.error({
                                        background: true,
                                        content: res.desc,
                                    })
                                }
                            })
                        }
                    } else {
                        this.btnLoadingMore = false
                        this.$Message.destroy()
                        this.$Message.error({
                            background: true,
                            content: res[1].desc || res[0].desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.btnLoadingMore = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        enterWindowStatusFn(status) {
            if (!status) {
                if (this.$route.query.dataScopeId && this.$route.query.houseCode && this.$route.query.replierId && this.$route.query.userName) {
                    var index = parent.layer.getFrameIndex(window.name) //先得到当前iframe层的索引
                    parent.layer.close(index) //再执行关闭
                }
                this.windowObj = {}
                location.replace(location.hash.substring(0, location.hash.lastIndexOf("#") != 0 ? location.hash.lastIndexOf("#") : location.hash.length))
            }
            this.enterWindowStatus = status
        },
        search() {
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        hadlePageSize(obj) {
            this.loading = true
            this.$get("/sendmsg/pc/chat/communityMessage/queryReplyLatestPage", {
                ...this.selectObj,
                status: this.selectObj.status == "all" ? "" : this.selectObj.status,
                page: this.page,
                pageSize: this.pageSize,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                hasSensitive: this.hasSensitive,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        res.dataList.map(item => {
                            item.userName = item.houseName ? item.houseName + "-" + item.replier : item.replier
                            item.gmtModified = item.gmtModified ? this.$core.formatDate(new Date(item.gmtModified), "yyyy-MM-dd hh:mm:ss") : ""
                        })
                        this.tableData = res.dataList
                        this.total = res.maxCount
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.loading = false
                    } else {
                        this.loading = false
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        getList(obj) {
            this.selectObj = {
                ...this.selectObj,
                nameOrMobile: "",
                communityCode: obj.communityCode,
                regionId: obj.regionId,
                regionType: obj.regionType,
                roadCode: obj.roadCode,
            }
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        backClick() {
            if (this.$route.query.isLineHome) {
                this.$router.push("/residentdatabase")
            }
        },
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
    },
    created() {
        this.tableHeight = window.innerHeight - 172 + ""
        console.log("进来了")
        if (this.$route.query.dataScopeId && this.$route.query.houseCode && this.$route.query.replierId && this.$route.query.userName) {
            this.windowObj.dataScopeId = this.$route.query.dataScopeId
            this.windowObj.houseCode = this.$route.query.houseCode
            this.windowObj.page = 1
            this.windowObj.pageSize = 20
            this.windowObj.replierId = this.$route.query.replierId
            this.windowObj.userName = this.$route.query.userName
            this.windowObj.index = 1 //标识第一次进入窗口
            this.$Message.loading({
                background: true,
                content: "正在加载数据，请稍等...",
            })
            this.getEnterWindowData()
            return
        }
    },
    filters: {
        filterTime(val, that) {
            if (val) {
                return that.$core.formatDate(new Date(val), "yyyy-MM-dd hh:mm:ss")
            } else {
                return ""
            }
        },
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
        LiefengTree,
    },
}
</script>

<style scoped lang="less">
// /deep/#toolsbarRight{
//   height: 30px;
// }
#container {
    padding: 0 4px;
    width: 100%;
    position: relative;
    height: calc(100vh - 216px);
    overflow-y: scroll;
    /deep/.flexStart {
        .ivu-list-items {
            justify-content: flex-start;
        }
    }
    /deep/.flexEnd {
        .ivu-list-items {
            justify-content: flex-end;
        }
    }
    /deep/.ivu-list-header {
        border-bottom: none;
    }
    /deep/.ivu-list-items {
        display: flex;
    }
    /deep/.ivu-list-item {
        border-radius: 4px;
        display: inline-block;
        padding: 12px;
    }
}
/deep/.ivu-anchor-ink {
    display: none;
}
/deep/.ivu-anchor-wrapper {
    height: 40px;
    line-height: 40px;
    position: fixed;
    right: 10px;
    width: 140px;
    border: 1px solid #47cb89;
    background-color: #fff;
    border-radius: 20px 0 0 20px;
    text-align: center;
    z-index: 9999;
    .ivu-anchor-link {
        line-height: 22px;
        a {
            color: #47cb89;
        }
    }
    .ivu-icon {
        position: absolute;
        top: 12px;
        left: 10px;
        color: #47cb89;
        font-size: 18px;
        z-index: -1;
    }
}
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    margin: 0 15px 0;
}
</style>
